import { useState, useCallback, useEffect } from "react";

export const useTuteurContext = () => {

    // UseState variables of the TeacherContext
    const [tuteurStatus, setTuteurStatus] = useState(false);
    const [tuteurRestricted, setTuteurRestricted] = useState(false);
    const [tuteurCampusId, setTuteurCampusId] = useState(false);
    const [tuteurCurrentPeriod, setTuteurCurrentPeriod] = useState(false);
    const [tuteurProfilePhoto, setTuteurProfilePhoto] = useState(false);
    const [tuteurName, setTuteurName] = useState(false);
    const [tuteurFinStatus, setTuteurFinStatus] = useState(false);
    const [tuteurLevel, setTuteurLevel] = useState(false);
    const [tuteurSchool, setTuteurSchool] = useState(false);
    const [tuteurAttestation, setTuteurAttestation] = useState(false);
    const [tuteurCertificate, setTuteurCertificate] = useState(false);
    const [tuteurNextYearLevel, setTuteurNextYearLevel] = useState(false);
    const [tuteurNextYearCampus, setTuteurNextYearCampus] = useState(false);
    const [tuteurRythme, setTuteurRythme] = useState(false);

    // settuteurContext use of useCallback for avoiding infinite loops
    const setTuteurContext = useCallback(
        (tuteurStatus, tuteurRestricted, tuteurCampusId, tuteurCurrentPeriod, tuteurProfilePhoto, tuteurName, tuteurFinStatus, tuteurLevel, tuteurSchool, tuteurAttestation, tuteurCertificate, tuteurNextYearLevel, tuteurNextYearCampus, tuteurRythme) => {

            // Assign Context State Variables
            setTuteurStatus(tuteurStatus);
            setTuteurRestricted(tuteurRestricted);
            setTuteurCampusId(tuteurCampusId);
            setTuteurCurrentPeriod(tuteurCurrentPeriod);
            setTuteurProfilePhoto(tuteurProfilePhoto);
            setTuteurName(tuteurName);
            setTuteurFinStatus(tuteurFinStatus);
            setTuteurLevel(tuteurLevel);
            setTuteurSchool(tuteurSchool);
            setTuteurAttestation(tuteurAttestation);
            setTuteurCertificate(tuteurCertificate);
            setTuteurNextYearLevel(tuteurNextYearLevel);
            setTuteurNextYearCampus(tuteurNextYearCampus);
            setTuteurRythme(tuteurRythme);

            // Check Stock context data in the browser's sessionStorage
            const storedContext = JSON.parse(sessionStorage.getItem("tuteurContext"));

            // Stock context data in the browser's sessionStorage
            if (!storedContext) {
                sessionStorage.setItem(
                    "tuteurContext",
                    JSON.stringify({
                        tuteurStatus,
                        tuteurRestricted,
                        tuteurCampusId,
                        tuteurCurrentPeriod,
                        tuteurProfilePhoto,
                        tuteurName,
                        tuteurFinStatus,
                        tuteurLevel,
                        tuteurSchool,
                        tuteurAttestation,
                        tuteurCertificate,
                        tuteurNextYearLevel,
                        tuteurNextYearCampus,
                        tuteurRythme
                    })
                );
            }
        }, []);

    // unsettuteurContext remets tout à null et supprime l'objet du sessionStorage
    const unsetTuteurContext = useCallback(() => {
        setTuteurStatus(null);
        setTuteurRestricted(null);
        setTuteurCampusId(null);
        setTuteurCurrentPeriod(null);
        setTuteurProfilePhoto(null);
        setTuteurName(null);
        setTuteurFinStatus(null);
        setTuteurLevel(null);
        setTuteurSchool(null);
        setTuteurAttestation(null);
        setTuteurCertificate(null);
        setTuteurNextYearLevel(null);
        setTuteurNextYearCampus(null);
        setTuteurRythme(null);
        sessionStorage.removeItem("tuteurContext");
    }, []);

    // Auto-set context to the app using sessionStorage
    useEffect(() => {
        
        // autofetch user context in the sessionStorage
        const storedContext = JSON.parse(sessionStorage.getItem("tuteurContext"));

        if (
            storedContext &&
            storedContext.tuteurStatus &&
            storedContext.tuteurRestricted &&
            storedContext.tuteurCampusId &&
            storedContext.tuteurCurrentPeriod &&
            storedContext.tuteurProfilePhoto &&
            storedContext.tuteurName &&
            storedContext.tuteurFinStatus &&
            storedContext.tuteurLevel &&
            storedContext.tuteurSchool &&
            storedContext.tuteurAttestation &&
            storedContext.tuteurCertificate &&
            storedContext.tuteurNextYearLevel &&
            storedContext.tuteurNextYearCampus &&
            storedContext.tuteurRythme
        ) {
            setTuteurContext(
                storedContext.tuteurStatus,
                storedContext.tuteurRestricted,
                storedContext.tuteurCampusId,
                storedContext.tuteurCurrentPeriod,
                storedContext.tuteurProfilePhoto,
                storedContext.tuteurName,
                storedContext.tuteurFinStatus,
                storedContext.tuteurLevel,
                storedContext.tuteurSchool,
                storedContext.tuteurAttestation,
                storedContext.tuteurCertificate,
                storedContext.tuteurNextYearLevel,
                storedContext.tuteurNextYearCampus,
                storedContext.tuteurRythme
            );
        }
    }, [setTuteurContext]);

    return {
        tuteurStatus,
        tuteurRestricted,
        tuteurCampusId,
        tuteurCurrentPeriod,
        tuteurProfilePhoto,
        tuteurName,
        tuteurFinStatus,
        tuteurLevel,
        tuteurSchool,
        tuteurAttestation,
        tuteurCertificate,
        tuteurNextYearLevel,
        tuteurNextYearCampus,
        tuteurRythme,
        setTuteurContext,
        unsetTuteurContext,
    };
};
