/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React, { useState, useContext } from "react";

import { StudentContext } from "../../../context/student-context";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import styles from "./FinancialStatus.module.scss";

const FinancialStatus = (props) => {
  // Authentication context

  // App context
  const [commitments] = useState(props.commitments);

  const studentContext = useContext(StudentContext);

  const suiviDossierMontage = [
    "dossier_en_cours_e",
    "dossier_en_cours_r",
    "dossier_en_cours_rr",
  ];

  const suiviDossierFinal = [
    "dossier_monte",
    "dossier_signe",
    "dossier_valide",
  ];

  // Fonction qui permet de parcourir tous les commitments pour : 1) transformer en tableau d'objets, 2) sortir le commitment current.
  const retrieveCurrentYearCommitment = () => {
    let currentCommitment;
    let nextYearCommitment;
    const commitmentToArray = Object.keys(commitments).map((commitment) => {
      return commitments[commitment];
    });

    commitmentToArray.map((commitment) => {
      if (commitment.caption == studentContext.studentCurrentPeriod) {
        currentCommitment = commitment;
      } else if (
        commitment.caption.split("-")[0] >
        studentContext.studentCurrentPeriod.split("-")[0]
      ) {
        nextYearCommitment = commitment;
      }
    });
    if (nextYearCommitment) return nextYearCommitment;
    else return currentCommitment;
  };

  // Ici on extorque le résultat et on vient le set.
  const result = retrieveCurrentYearCommitment();
  const [currentCommitment] = useState(result);

  return (
    <div className={styles.financialContainer}>
      {(!currentCommitment ||
        (currentCommitment && !currentCommitment.property_12)) && (
        <p style={{ color: "#e40000" }}>
          Erreur sur le serveur. Veuillez réessayer ultèrieurement.
        </p>
      )}
      {currentCommitment && currentCommitment.property_12 && (
        <>
          <p className={styles.financing}>
            {currentCommitment.property_12 === "financing_company"
              ? "Financement entreprise"
              : "Financement personnel"}
          </p>
          <p
            className={styles.financingP}
            style={{
              color:
                suiviDossierMontage.includes(currentCommitment.suivi_dossier) ||
                suiviDossierFinal.includes(currentCommitment.suivi_dossier)
                  ? "#2CB369"
                  : "none",
            }}
          >
            Montage en cours
          </p>
          <p className={styles.Pmontage}>
            Félicitations, vous avez trouvé votre entreprise ! Nous attendons
            maintenant la fiche de renseignements remplie par votre entreprise
            pour monter votre dossier.
          </p>
          <KeyboardArrowDownIcon />
          <p
            className={styles.dossier}
            style={{
              color: suiviDossierFinal.includes(currentCommitment.suivi_dossier)
                ? "#2CB369"
                : "none",
            }}
          >
            Dossier monté
          </p>
          <p className={styles.Pmontage}>
            Votre dossier a bien été monté. Nous attendons maintenant de
            recevoir la convention signée par votre entreprise.
          </p>
          <KeyboardArrowDownIcon />
          <p
            className={styles.dossier}
            style={{
              color:
                currentCommitment.suivi_dossier == "dossier_signe" ||
                currentCommitment.suivi_dossier == "dossier_valide"
                  ? "#2CB369"
                  : "none",
            }}
          >
            Dossier signé
          </p>
          <p className={styles.Pmontage}>
            Votre dossier est signé. Vous êtes officiellement en alternance et
            ne serez plus prélevés de vos frais de scolarité.
          </p>
          <KeyboardArrowDownIcon />
          <p
            className={styles.dossier}
            style={{
              color:
                currentCommitment.suivi_dossier == "dossier_valide"
                  ? "#2CB369"
                  : "none",
            }}
          >
            Dossier validé
          </p>
          <p className={styles.Pmontage}>
          Nous avons bien réceptionné la convention de formation signée par votre
          entreprise. Le remboursement des frais avancés avant contrat sera effectif
          une fois la période d'essai validée.
          </p>
        </>
      )}
    </div>
  );
};

export default FinancialStatus;
